// Import the primary font
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

// Define the primary font as a variable
$primary-font: "Lato", sans-serif;

// Import the secondary font
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;700&display=swap");

// Define the secondary font as a variable
$secondary-font: "Poppins", sans-serif;
